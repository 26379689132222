class ClsSearch {

  static list = function(text, temp_list, created_list, param_list) {
    // Özellikler:
    // - Arama textin direkt kontrolünü yapıyoruz. Bu var ise first_rowsa ekliyoruz. index 0 ise bunu first rows'a değilse all içerisine push edip sıralıyoruz.
    // - Arama textinde ilişkili harfler var ise kendisi olmayıp ilişkili olanlar var ise bu durumu pozitif olarak alıyoruz.
    // - Arama textinde aranan harflerin ardışık olarak arada maksimum uymayan 1 harf olacak şekilde bulunmasını istiyoruz. Örneğin korkmazı => krmz şeklinde arayabiliriz.
    let char_relation = {
      'i': ['İ', 'I', 'ı'],
      'İ': ['I', 'i', 'ı'],
      'ı': ['İ', 'I', 'i'],
      'I': ['I', 'i', 'ı'],
      'g': ['ğ'],
      'ğ': ['g'],
      'ö': ['o'],
      'o': ['ö'],
      'ü': ['u'],
      'u': ['ü'],
      'ç': ['c'],
      'c': ['ç'],
      'ş': ['s'],
      's': ['ş']
    };
    let first_rows = [];
    let all_abbr = {};
    let all_not_abbr = {};
    let percent_abbr = {};
    let percent_not_abbr = {};
    // text = text.toLowerCase();
    text = text.toLocaleLowerCase('tr');
    text = text.replace(/ /g, '');
    created_list = [];
    if (text && temp_list.length > 0) {
      let y = text.length;
      for (let t in temp_list) {
        // her bir rowdaki bulunurluğa bakıp pozitif bulunursa x değerine ekliyoruz.
        let row = temp_list[t];
        let txt = '';
        for (let y in param_list) { // ilgili dize içerisinden önce text üretiliyor. Bu text üzerinde aram işlemleri yapılıyor.
          if (typeof param_list[y] === 'string') {
            txt += row[param_list[y]];
          } else {
            var ind;
            let tmp_row = JSON.parse(JSON.stringify(row));
            let tmp_text = '';
            try {
              for (ind = 0; ind < param_list[y].length; ind++) {
                tmp_row = JSON.parse(JSON.stringify(tmp_row[param_list[y][ind]]));
              }
              tmp_text = tmp_row;
            } catch (err) {}
            txt += tmp_text;
          }
        }
        txt = txt.replace(/ /g, '');
        let txt_ind = this.f_indexLocation(txt, text, 'first');
        if (txt_ind !== -1) {
          // ilk indexten başlıyorsa ilk sırada olacak
          if (txt_ind === 0) {
            first_rows.push(row);
          } else {
            if (txt.indexOf('>>>') !== -1) {
              if (!all_abbr[txt_ind]) {
                all_abbr[txt_ind] = [];
              }
              all_abbr[txt_ind].push(row);
            } else {
              if (!all_not_abbr[txt_ind]) {
                all_not_abbr[txt_ind] = [];
              }
              all_not_abbr[txt_ind].push(row);
            }
          }
        } else {
          let loc = {};
          let x = 0;
          let z = true;
          let char_let_count = 0;
          for (let i in text) {
            char_let_count = i;
            let char = text[i];
            if (loc.length === 0) {
              loc = this.f_indexLocation(txt, text[i], 'all', true);
            }
            let char_index = this.f_indexLocation(txt, text[i], 'all');
            // console.log(char_index);
            if (char_index.length > 0) {
              for (let sub_loc in loc) {
                let break_for = false;
                if (char_index.indexOf(loc[sub_loc]['last_ind']) === -1) {
                  for (let char_index_ind in char_index) {
                    let i_char = char_index[char_index_ind];
                    if (i_char > loc[sub_loc]['last_ind']) { // yeni tespit edilen charın indexi ile bir önceki tespit edilen arasında sadece bir karakter olması
                      loc[sub_loc]['last_ind'] = i_char;
                      loc[sub_loc]['count'] += 1;
                      break_for = true;
                      break;
                    }
                    // if (i_char > loc[sub_loc]['last_ind'] && i_char - loc[sub_loc]['last_ind'] <= 2) { // yeni tespit edilen charın indexi ile bir önceki tespit edilen arasında sadece bir karakter olması
                    //   loc[sub_loc]['last_ind'] = i_char;
                    //   loc[sub_loc]['count'] += 1;
                    // }
                  }
                  if (break_for) {
                    continue;
                  }
                }
              }
            } else {
              // ilgili karakterle ilişkili arama ihtimali olan diğer karakterlerle ilişki kuruyoruz.
              if (char_relation[char]) {
                // console.log('char', char);
                // console.log('char_relation[char]', char_relation[char])
                let n = false;
                for (let r in char_relation[char]) {
                  let break_r_for = false;
                  let r_char = char_relation[char][r];
                  if (loc.length === 0) {
                    loc = this.f_indexLocation(txt, r_char, 'all', true);
                  }
                  let r_char_index = this.f_indexLocation(txt, r_char, 'all');
                  for (let sub_loc in loc) {
                    for (let r_char_index_ind in r_char_index) {
                      let i_r_char = r_char_index[r_char_index_ind]
                      if (char_index.indexOf(loc[sub_loc]['last_ind']) === -1) {
                        // ardışıklık kontrolü
                        if (i_r_char > loc[sub_loc]['last_ind']) {
                          loc[sub_loc]['last_ind'] = i_r_char;
                          loc[sub_loc]['count'] += 1;
                          x += 1;
                          // n = true;
                          break_r_for = true;
                          break;
                        }
                        // if (i_r_char > loc[sub_loc]['last_ind'] && i_r_char - loc[sub_loc]['last_ind'] <= 2) {
                        //   loc[sub_loc]['last_ind'] = i_r_char;
                        //   loc[sub_loc]['count'] += 1;
                        //   x += 1;
                        //   n = true;
                        // }
                      }
                    }
                    if (break_r_for) {
                      continue; // related karakterlerinden birisini bulması yeterli kabul ediyoruz.
                    }
                  }
                  if (break_r_for) {
                    break;
                  }
                }
              }
            }
          }
          // console.log(loc);
          for (let sub_loc in loc) {
            if (y === 1) {
              if ((loc[sub_loc]['count'] / y) >= 1) {
                if (txt.indexOf('>>>') !== -1) {
                  if (!percent_abbr[sub_loc]) {
                    percent_abbr[sub_loc] = [];
                  }
                  percent_abbr[sub_loc].push(row);
                  // break;
                } else {
                  if (!percent_not_abbr[sub_loc]) {
                    percent_not_abbr[sub_loc] = [];
                  }
                  percent_not_abbr[sub_loc].push(row);
                  // break;
                }
              }
            } else if (y === 2) {
              if ((loc[sub_loc]['count'] / y) > 0.95) {
                if (txt.indexOf('>>>') !== -1) {
                  if (!percent_abbr[sub_loc]) {
                    percent_abbr[sub_loc] = [];
                  }
                  percent_abbr[sub_loc].push(row);
                  // break;
                } else {
                  if (!percent_not_abbr[sub_loc]) {
                    percent_not_abbr[sub_loc] = [];
                  }
                  percent_not_abbr[sub_loc].push(row);
                  // break;
                }
              }
            } else if (y === 3) {
              if ((loc[sub_loc]['count'] / y) > 0.65) {
                if (txt.indexOf('>>>') !== -1) {
                  if (!percent_abbr[sub_loc]) {
                    percent_abbr[sub_loc] = [];
                  }
                  percent_abbr[sub_loc].push(row);
                  // break;
                } else {
                  if (!percent_not_abbr[sub_loc]) {
                    percent_not_abbr[sub_loc] = [];
                  }
                  percent_not_abbr[sub_loc].push(row);
                  // break;
                }
              }
            } else if (y >= 4) {
              // console.log('loc[sub_loc]['count']:', loc[sub_loc]['count']);
              // console.log('y:', y);
              if ((loc[sub_loc]['count'] / y) >= 0.75) {
                if (txt.indexOf('>>>') !== -1) {
                  if (!percent_abbr[sub_loc]) {
                    percent_abbr[sub_loc] = [];
                  }
                  percent_abbr[sub_loc].push(row);
                  // break;
                } else {
                  if (!percent_not_abbr[sub_loc]) {
                    percent_not_abbr[sub_loc] = [];
                  }
                  percent_not_abbr[sub_loc].push(row);
                  // break;
                }
              }
            }
          }
        }
      }
      // console.log('all', all);
      // console.log('percent', percent);
      let is_all_unique = false;
      let all_unique = [];
      for (let all_abbr_key_index in Object.keys(all_abbr).sort()) {
        let all_abbr_item = Object.keys(all_abbr)[all_abbr_key_index];
        // console.log(all_abbr_item);
        if (!is_all_unique) {
          is_all_unique = true;
          all_unique = JSON.parse(JSON.stringify(all_abbr[all_abbr_item]));
        } else {
          all_unique = all_unique.concat(all_abbr[all_abbr_item]);
        }
        all_unique = [...new Set(all_unique)];
      }
      for (let all_not_abbr_key_index in Object.keys(all_not_abbr).sort()) {
        let all_not_abbr_item = Object.keys(all_not_abbr)[all_not_abbr_key_index];
        // console.log(all_not_abbr_item);
        if (!is_all_unique) {
          is_all_unique = true;
          all_unique = JSON.parse(JSON.stringify(all_not_abbr[all_not_abbr_item]));
        } else {
          all_unique = all_unique.concat(all_not_abbr[all_not_abbr_item]);
        }
        all_unique = [...new Set(all_unique)];
      }
      // console.log('all_unique', all_unique);
      let is_percent_unique = false;
      let percent_unique = [];
      for (let percent_abbr_key_index in Object.keys(percent_abbr).sort()) {
        let percent_abbr_item = Object.keys(percent_abbr)[percent_abbr_key_index];
        // console.log(percent_abbr_item);
        if (!is_percent_unique) {
          is_percent_unique = true;
          percent_unique = JSON.parse(JSON.stringify(percent_abbr[percent_abbr_item]));
        } else {
          percent_unique = percent_unique.concat(percent_abbr[percent_abbr_item]);
        }
        percent_unique = [...new Set(percent_unique)];
      }
      for (let percent_not_abbr_key_index in Object.keys(percent_not_abbr).sort()) {
        let percent_not_abbr_item = Object.keys(percent_not_abbr)[percent_not_abbr_key_index];
        // console.log(percent_not_abbr_item);
        if (!is_percent_unique) {
          is_percent_unique = true;
          percent_unique = JSON.parse(JSON.stringify(percent_not_abbr[percent_not_abbr_item]));
        } else {
          percent_unique = percent_unique.concat(percent_not_abbr[percent_not_abbr_item]);
        }
        all_unique = [...new Set(all_unique)];
      }
      // console.log('percent_unique', percent_unique);
      // for (let i in first_rows) {
      //   if (created_list.indexOf(first_rows[i]) === -1) {
      //     created_list.push(first_rows[i]);
      //   }
      // }
      // for (let i in all_unique) {
      //   if (created_list.indexOf(all_unique[i]) === -1) {
      //     created_list.push(all_unique[i]);
      //   }
      // }
      // for (let i in percent_unique) {
      //   if (created_list.indexOf(percent_unique[i]) === -1) {
      //     created_list.push(percent_unique[i]);
      //   }
      // }
      created_list = created_list.concat(first_rows, all_unique, percent_unique);
    } else {
      created_list = JSON.parse(JSON.stringify(temp_list));
    }
    return created_list;
  };

  static f_getParamListDetail = function(row, info, ind) {
    if (ind === info.length - 1) {
      console.log(row[info[ind]]);
      return row[info[ind]];
    } else {
      let new_ind = ind + 1;
      this.f_getParamListDetail(row[info[ind]], info, new_ind);
    }
  }

  static f_indexLocation = function(search_text, find, type = 'all', is_object = false) {
    // search_text = search_text.toLowerCase();
    search_text = search_text.toLocaleLowerCase('tr');
    // find = find.toLowerCase();
    find = find.toLocaleLowerCase('tr');
    if (type === 'all') {
      if (is_object === true) {
        let indices = {};
        for (let i = 0; i < search_text.length; i++) {
          if (search_text[i] === find) {
            indices[i] = { 'last_ind': i, 'count': 1 };
          }
        }
        return indices;
      } else {
        let indices = [];
        for (let i = 0; i < search_text.length; i++) {
          if (search_text[i] === find) {
            indices.push(i);
          }
        }
        if (indices.length === 0) {
          return -1;
        } else {
          return indices;
        }
      }
    }

    if (type === 'first') {
      return search_text.indexOf(find);
    }

    // ilgili search textin bulunduğu son lokasyon indexini tespit ediyoruz.
    if (type === 'last') {
      if (search_text.indexOf(find) === -1) {
        return -1;
      } else {
        // kelimeyi tersine çevirip indexini alıp toplam uzunluktan çıkarıyoruz.
        return search_text.length - search_text.split('').reverse().indexOf(find);
      }
    }
  }
};

export { ClsSearch };
