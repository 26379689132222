<template>
  <div>
    <b-card class="card-accent-primary" header-tag="header">
      <div slot="header">
        <strong>Kullanıcılar Listesi</strong>
      </div>
      <b-row>
        <b-col cols="2">Filtrele</b-col>
        <b-col cols="10">
          <b-form-input v-model="search_text"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <table class="userListForWisdomTable">
            <tr class="userListForWisdomTr">
              <th class="userListForWisdomTh">Kullanıcı Adı</th>
              <th class="userListForWisdomTh">Ad-Soyad</th>
              <th class="userListForWisdomTh">Grup</th>
              <th class="userListForWisdomTh">Hesaba Geç</th>
            </tr>
            <template v-for="(user, user_ind) in d_filteredUserList">
              <tr class="userListForWisdomTr" :style="f_calculateRowStyle(user_ind)">
                <td class="userListForWisdomTd">
                  {{ user.username }}
                </td>
                <td class="userListForWisdomTd">
                  {{ user.first_name + ' ' + user.last_name }}
                </td>
                <td class="userListForWisdomTd">
                  {{ user.group }}
                </td>
                <td class="userListForWisdomTd">
                  <b-button block variant="primary" @click="changeAccountWithoutPassword(user.username)"><i class="fa fa-edit fa-1x"></i> Hesaba Geç</b-button>
                </td>
              </tr>
            </template>
          </table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import _nav from '@/_nav';
import auth from '@/auth';
// import Vue from 'vue';
import { mapGetters } from 'vuex';
// import Vue from 'vue';
import {
  default as router
} from '@/router';
// import { API_BASE_URL } from '@/config';
import UserService from '@/services/users';
import { ClsSearch } from '@/services/public/search';

export default {
  name: 'UserListForWisdom',
  computed: {
    ...mapGetters({})
  },
  props: {
    main_account_username: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      search_text: '',
      columns: [
        { key: 'username', label: 'Kullanıcı Adı', sortDirection: 'desc' },
        { key: 'first_name', label: 'Ad' },
        { key: 'last_name', label: 'Soyad' },
        { key: 'group', label: 'Grup' },
        { key: 'actions', label: 'Hesaba Geç' }
      ],
      d_allUserList: [],
      d_filteredUserList: []
    };
  },
  created: function() {
    let account_list = JSON.parse(localStorage.getItem('account_list'));
    let data = {};
    data.active_status = 'active'; // 'active, 'passive', 'all'
    if (account_list[0].special_permission.indexOf('wisdom') !== -1) {
      data.forbidden_perms = ['wisdom']; // array
    } else {
      data.forbidden_perms = ['wisdom', 'administrator']; // array
    }
    data.forbidden_usernames = []; // array
    data.needed_perms = []; // array
    data.needed_perm_type = 'or'; // 'or', 'and'
    UserService.get_users_with_filter(data)
      .then(resp => {
        for (let i in resp.data.result) {
          if (resp.data.result[i].username !== this.main_account_username) {
            this.d_allUserList.push(resp.data.result[i]);
          }
        }
        this.d_filteredUserList = JSON.parse(JSON.stringify(this.d_allUserList));
      });
  },
  methods: {
    f_calculateRowStyle: function(user_ind) {
      if (user_ind % 2 === 0) {
        return '';
      } else {
        return 'background-color: #ececec;';
      }
    },
    changeAccountWithoutPassword: function(username) {
      let account_login_data = {
        'username': username,
        'password': 'password',
        'multi_account_password': 0,
        'not_control_password': 1
      };
      // this.$router.push('/settings/user');
      auth.login(this, account_login_data, '/dashboard').then(resp => {
        _nav.f_updateItems();
        location.reload();
      }, resp => {
        _nav.f_updateItems();
        location.reload();
      });
    },
    go_back: function() {
      router.go(-1);
    },
    go_new_user: function() {
      router.push({ path: '/user/users/new', query: {} });
    },
    users_delete: function(username) {
      UserService.delete(username)
        .then(resp => {
          this.$refs.users_table.refresh();
        });
    },
    users_edit: function(username) {
      router.push({ path: '/settings/hospital/users/edit', query: { 'username': username, 'edit': true } });
    }
  },
  watch: {
    'search_text': function () {
      this.d_filteredUserList = ClsSearch.list(this.search_text, this.d_allUserList, this.d_filteredUserList, ['username', 'first_name', 'last_name']);
    }
  },
  components: {
  }
};

</script>

<style>
.userListForWisdomTable {
  border: solid 1px; width: 100%;

}
.userListForWisdomTr {
  border: solid 1px;
  
}
.userListForWisdomTd {
  border: solid 1px;
  
}
.userListForWisdomTh {
  border: solid 1px; text-align: center; background-color: #ececec;
}
</style>